










































import type { FlexibleQuote } from '@/inc/types'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

import { gsap } from 'gsap'
import SplitText from 'gsap/dist/SplitText.js'
gsap.registerPlugin(SplitText)

import UiAuthor from '@/components/ui/Author.vue'

export default defineComponent({
  name: 'FlexibleQuote',
  components: { UiAuthor },
  props: {
    content: {
      type: Object as () => FlexibleQuote,
      required: true,
    },
  },
  setup(props, ctx) {
    const rootElRef = ref<HTMLElement | null>(null)
    const linkElRef = ref<HTMLElement | null>(null)
    const textElRef = ref<Element | null>(null)
    const tl = ref()
    const textInner = ref()
    const splitContent = ref()

    tl.value = gsap.timeline()

    onMounted(() => {
      textInner.value = textElRef.value!.querySelector('p')
      splitContent.value = new SplitText(textInner.value, {
        type: 'words',
      })
      tl.value
        .set(linkElRef.value, {
          opacity: 0,
          y: 20,
        })
        .set([...splitContent.value.words], {
          opacity: 0,
        })
    })

    const shuffle = array => {
      let currentIndex = array.length
      let temporaryValue
      let randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }

    const onAppear = isInViewport => {
      if (isInViewport) {
        tl.value
          .to(
            shuffle([...splitContent.value.words]),
            {
              duration: 0.5,
              opacity: 1,
              stagger: 0.005,
              ease: 'power4.out',
            },
            '0.3'
          )
          .to(
            linkElRef.value,
            {
              duration: 0.5,
              opacity: 1,
              y: 0,
              ease: 'power4.out',
            },
            '<0.5'
          )
      }
    }

    // Push event to datalayer on click
    const onLinkClick = () => {
      ctx.emit('click', props.content)

      if (!props.content.link || !rootElRef.value) {
        return
      }

      const ctaLabel = (props.content.link.label ||
        props.content.link.title) as string

      const layer: Partial<GtmCta> = {
        clickText: ctaLabel,
        ctaLabel,
        ctaType: 'quote',
        ctaUrl: props.content.link.url,
      }

      pushCta(layer, rootElRef.value)
    }

    return {
      rootElRef,
      linkElRef,
      textElRef,
      onAppear,
      onLinkClick,
    }
  },
})
